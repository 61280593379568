// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    .error-form p {
        display: none;
    }
}


@media (min-width: 1100px) {
    .form-field {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    
        .card {
            display: flex;
            width: 500px;
        }
        
    }
    
}

@media (max-width: 1099px) {

    body {
        
        .form-field {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;
        
            .card {
                margin: auto;
                width: 300px;
                margin-top: -20px;
            }
            
        }
    }
}`, "",{"version":3,"sources":["webpack://./frontend/assets/css/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;AACJ;;;AAGA;IACI;QACI,aAAa;QACb,iBAAiB;QACjB,mBAAmB;QACnB,uBAAuB;;QAEvB;YACI,aAAa;YACb,YAAY;QAChB;;IAEJ;;AAEJ;;AAEA;;IAEI;;QAEI;YACI,aAAa;YACb,sBAAsB;YACtB,mBAAmB;YACnB,uBAAuB;YACvB,aAAa;;YAEb;gBACI,YAAY;gBACZ,YAAY;gBACZ,iBAAiB;YACrB;;QAEJ;IACJ;AACJ","sourcesContent":["body {\r\n    .error-form p {\r\n        display: none;\r\n    }\r\n}\r\n\r\n\r\n@media (min-width: 1100px) {\r\n    .form-field {\r\n        display: flex;\r\n        flex-wrap: nowrap;\r\n        align-items: center;\r\n        justify-content: center;\r\n    \r\n        .card {\r\n            display: flex;\r\n            width: 500px;\r\n        }\r\n        \r\n    }\r\n    \r\n}\r\n\r\n@media (max-width: 1099px) {\r\n\r\n    body {\r\n        \r\n        .form-field {\r\n            display: flex;\r\n            flex-direction: column;\r\n            align-items: center;\r\n            justify-content: center;\r\n            padding: 30px;\r\n        \r\n            .card {\r\n                margin: auto;\r\n                width: 300px;\r\n                margin-top: -20px;\r\n            }\r\n            \r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
