import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './assets/css/index.css'
import Login from './modules/login'

const login = new Login('.form-login')
const cadastro = new Login('.form-cadastro')
const contato = new Login('.form')
login.init()
cadastro.init()
