const validator = require('validator')

export default class Login {
    constructor(formClass) {
        this.form = document.querySelector(formClass)
    }

    init() {
        this.events()
    }
    
    events() {
        if(!this.form) return
        this.form.addEventListener('submit', e => {
            e.preventDefault()
            this.validate(e)
        })

    }
    
    validate(e) {
        const el = e.target
        const emailInput = el.querySelector('input[name="email"]')
        console.log(emailInput)
        const senhaInput = el.querySelector('input[name="senha"]')
        const errorLogin = document.querySelector('.error-form .error-email-login')
        const errorCadastro = document.querySelector('.error-form .error-email-cadaster')
        
        let error = false
        
        if(this.form === document.querySelector('.form-login') && !validator.isEmail(emailInput.value)) {
            error = true
            errorLogin.style.display = 'block'
            errorLogin.innerText = 'Email inválido'
        }

        if(this.form === document.querySelector('.form-login') && senhaInput.value.length < 3 || senhaInput.value.length > 50) {
            error = true
            errorLogin.style.display = 'block'
            errorLogin.innerText = 'Senha inválida'
        }
        
        if(this.form === document.querySelector('.form-cadastro') && !validator.isEmail(emailInput.value)) {
            error = true
            errorCadastro.style.display = 'block'
            errorCadastro.innerText = 'Email inválido'
        }

        if(this.form === document.querySelector('.form-cadastro') && senhaInput.value.length < 3 || senhaInput.value.length > 50) {
            error = true
            errorCadastro.style.display = 'block'
            errorCadastro.innerText = 'Senha precisa ter entre 3 a 50 caracteres'
        }

        if(this.form === document.querySelector('.form-login') && !validator.isEmail(emailInput.value) && senhaInput.value.length < 3 || senhaInput.value.length > 50) {
            error = true
            errorLogin.style.display = 'block'
            errorLogin.innerText = 'O email e a senha são inválidos'
        }

        if(this.form === document.querySelector('.form-cadastro') && !validator.isEmail(emailInput.value) && senhaInput.value.length < 3 || senhaInput.value.length > 50) {
            error = true
            errorCadastro.style.display = 'block'
            errorCadastro.innerText = 'O email e a senha são inválidos'
        }

        if(!error){
         el.submit()
        }
    }
}